class MatrizService {
  constructor() {}
  resources = () => ({
    listarCapex: {
      uri: `/v1/presupuesto/capex/listar-header-capex`,
      method: ['get']
    },
    exportarOpexCampo: {
      uri: `/v1/presupuesto/exportar-versiones-capex`,
      method: ['get']
    },
    exportarOpexTI: {
      uri: `/v1/presupuesto/exportar-versiones-ti`,
      method: ['get']
    },
    exportarGeneralCapex: {
      uri: `/v1/exportar-capex-total`,
      method: ['get']
    },
    cambiarEstado: {
      uri: `/v1/presupuesto/opex/cambio-estado`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    eliminar: {
      uri: `/v1/presupuesto/eliminacion-logica`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    },
    permisos: {
      uri: `/v1/usuario/permisos`,
      method: ['get']
    },
    cargarAnios: {
      uri: `/v1/presupuesto/anios`,
      method: ['get']
    }
  });
}

export default MatrizService;
