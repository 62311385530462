<template>
  <div>
    <v-row class="mb-2 mt-4">
      <v-col cols="12" sm="4" lg="2" class="py-0 mb-2">
        <v-card>
          <v-card-text class="pa-1 pb-0">
            <div class="d-flex align-center">
              <div class="position-relative float-right pt-1 mx-3" style="height: 50px;">
                <v-icon color="warning" dark large>mdi-focus-field-horizontal</v-icon>
              </div>
              <div class="pt-1 ml-3">
                <h2 class="font-weight-light">{{ formatosPendientes }}</h2>
                <p class="font-weight-bold" style="font-size:14px">F. Pendientes</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" lg="2" class="py-0 mb-2">
        <v-card>
          <v-card-text class="pa-1 pb-0">
            <div class="d-flex align-center">
              <div class="position-relative float-right pt-1 mx-3" style="height: 50px;">
                <v-icon color="green" dark large>mdi-check-all</v-icon>
              </div>
              <div class="pt-1 ml-3">
                <h2 class="font-weight-light">{{ formatosAprobados }}</h2>
                <p class="font-weight-bold" style="font-size:14px">F. Aprobados</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" lg="2" class="py-0 mb-2">
        <v-card>
          <v-card-text class="pa-1 pb-0">
            <div class="d-flex align-center">
              <div class="position-relative float-right pt-1 mx-3" style="height: 50px;">
                <v-icon color="red darken-1" dark large>mdi-close-circle</v-icon>
              </div>
              <div class="pt-1 ml-3">
                <h2 class="font-weight-light">{{ formatosAnulados }}</h2>
                <p class="font-weight-bold" style="font-size:14px">F. Anulados</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="py-0 mb-2 mt-2 d-flex align-center justify-end">
        <v-select
          dense
          hide-details
          class="mr-4"
          v-model="anio"
          :items="listAnios"
          @change="initialize"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Año"
        ></v-select>
        <v-select
          dense
          hide-details
          class="mr-4"
          :items="listadoEstados"
          v-model="idEstado"
          label="Estado"
          @change="initialize"
        ></v-select>
        <v-select
          dense
          hide-details
          multiple
          v-model="areas"
          :items="itemsAreas"
          @blur="initialize"
          no-data-text="No se encontró información"
          :return-object="false"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
        <v-tooltip v-auth-acl="`${vAuthAcl}-export`" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small class="mx-4 ml-8" @click="exportarbase" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Exportar BD</span>
        </v-tooltip>
        <v-tooltip v-auth-acl="`${vAuthAcl}-insert`" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="registro" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar CAPEX {{ nombreTipoCapex }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="listado"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
      :height="height"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">CAPEX {{ nombreTipoCapex.toUpperCase() }}</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 pt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </div>
      </template>
      <template v-slot:[`item.Total`]="{ item }">
        {{ Number(item.Total).toFixed(2) }}
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <v-row>
          <v-col cols="3" class="d-flex">
            <template v-if="item.Eseliminar">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                    <v-icon small dark>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-if="item.Eseditar">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-edit'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="editItem(item)">
                    <v-icon small dark>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esgenerarversion">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-version'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="generateVersion(item)"
                  >
                    <v-icon small dark>
                      mdi-plus-box-multiple
                    </v-icon>
                  </v-btn>
                </template>
                <span>Generar Versión</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esvisualizar">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-view'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="viewVersions(item)">
                    <v-icon small dark>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ver Versiones</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esexportar">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-export'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="exportar(item)">
                    <v-icon small dark>
                      mdi-cloud-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>Exportar</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esaprobar">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-ap'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="aprobar(item, 1)">
                    AP
                  </v-btn>
                </template>
                <span>Aprobar</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esanular">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-an'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="aprobar(item, 0)">
                    AN
                  </v-btn>
                </template>
                <span>Anular</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esv1">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-v1'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" class="mr-1" v-bind="attrs" v-on="on" @click="darVisto(item, 1)">
                    V1
                  </v-btn>
                </template>
                <span>Dar Visto 1</span>
              </v-tooltip>
            </template>
            <template v-if="item.Esv2">
              <v-tooltip bottom v-auth-acl="vAuthAcl + '-v2'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small color="primary" v-bind="attrs" v-on="on" @click="darVisto(item, 2)">
                    V2
                  </v-btn>
                </template>
                <span>Dar Visto 2</span>
              </v-tooltip>
            </template>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-icon :color="item.activo == 1 ? 'green' : 'red darken-2'" small>
          {{ item.activo == 1 ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'MatrizLista',
  components: {},
  data: () => ({
    height: 130,
    search: '',
    showLoading: false,
    matrizService: null,
    dialogImportar: false,
    listadoEstados: [
      { value: '', text: 'Todos' },
      { value: 'PE', text: 'Pendiente' },
      { value: 'AP', text: 'Aprobado' },
      { value: 'AN', text: 'Anulado' }
      /*,
      { value: 'R1', text: 'Rechazado 1' },
      { value: 'R1', text: 'Rechazado 2' },
      { value: 'V1', text: 'Visto Bueno 1' },
      { value: 'V2', text: 'Visto Bueno 2' }*/
    ],
    headers: [
      { text: 'N°', value: 'numero', width: '100', class: 'header-items-fixed-ind nth-child1 primary', fixed: true },
      {
        text: 'OPCIONES',
        value: 'opciones',
        width: '150',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'USUARIO', value: 'Usuario', width: '120', class: 'primary' },
      { text: 'RESPONSABLE', value: 'Responsable', width: '160', class: 'primary' },
      { text: 'ÁREA', value: 'IdArea', width: '160', class: 'primary' },
      { text: 'ESTADO ACT', value: 'EstadoActual', width: '140', class: 'primary', align: 'center' },
      { text: 'ESTADO ANT', value: 'EstadoAnterior', width: '140', class: 'primary', align: 'center' },
      { text: 'FECHA', value: 'Fecha', width: '160', class: 'primary', align: 'center' },
      { text: 'HORA', value: 'Hora', width: '160', class: 'primary', align: 'center' },
      { text: 'OBSERVACIONES', value: 'Observaciones', width: '300', class: 'primary' },
      { text: 'VERSION ACTUAL', value: 'VersionActual', width: '180', class: 'primary', align: 'center' },
      { text: 'VERSION ANTERIOR', value: 'VersionAnterior', width: '190', class: 'primary', align: 'center' },
      { text: 'EDICIÓN', value: 'Edicion', width: '140', class: 'primary', align: 'center' },
      { text: 'MULTIPLES ÁREAS', value: 'MultiplesAreas', width: '180', class: 'primary', align: 'center' },
      { text: 'TOTAL', value: 'Total', width: '100', class: 'primary', align: 'right' },
      { text: 'FECHA CREACION', value: 'FechaCreacion', width: '130', class: 'primary', align: 'left' },
      { text: 'HORA CREACION', value: 'HoraCreacion', width: '130', class: 'primary', align: 'left' }
    ],
    listado: [],
    idEstado: '',
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('YYYY-MM-DD')} ~ ${moment().format('YYYY-MM-DD')}`,
    anio: new Date().getFullYear().toString(),
    listAnios: [],
    areas: [],
    itemsAreas: []
  }),
  props: {
    idTipoCapex: {
      type: String,
      default: ''
    },
    tipoCapex: {
      type: String,
      default: ''
    },
    nombreTipoCapex: {
      type: String,
      default: ''
    },
    vAuthAcl: {
      type: String,
      default: ''
    }
  },
  computed: {
    formatosPendientes() {
      let cant = 0;
      this.listado.forEach((element) => {
        if (element.EstadoActual == 'PE') {
          cant++;
        }
      });
      return cant;
    },
    formatosAnulados() {
      let cant = 0;
      this.listado.forEach((element) => {
        if (element.EstadoActual == 'AN') {
          cant++;
        }
      });
      return cant;
    },
    formatosAprobados() {
      let cant = 0;
      this.listado.forEach((element) => {
        if (element.EstadoActual == 'AP') {
          cant++;
        }
      });
      return cant;
    },
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    async dateRange(val) {
      if (val.length === 1) {
        this.dateRangeText = `${val[0]} ~ `;
      }
      if (val.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? val[0] : val[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? val[0] : val[1];
        this.dateRangeText = `${min} ~ ${max}`;
        this.areas = [];
        await this.initialize();
        await this.cargarAreas();
      }
    }
  },
  methods: {
    async listarYCargarAreas() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    },
    async exportar(item) {
      this.showLoading = true;
      let versionall = null;
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Qué versión desea exportar?',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'Actual',
        denyButtonText: 'Todas',
        cancelButtonText: 'Cancelar'
      });
      if (confirmed.isConfirmed) {
        versionall = 0;
      } else if (confirmed.isDenied) {
        versionall = 1;
      }
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-CAPEX-' + this.nombreTipoCapex.toUpperCase() + '-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      let url = this.tipoCapex == 'ti' ? 'exportarOpexTI' : 'exportarOpexCampo';
      await this.matrizService.downloadResource(
        url,
        {
          versionall: versionall,
          idcapexbudget: this.tipoCapex == 'ti' ? item.idopextibudget : item.idcapexbudget,
          token: token
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async exportarbase() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de exportar la información?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const fecha1 = moment().format('YYYYMMDDHHMMSS');
        const nombreentregable = 'EXPORT-CAPEX-' + this.nombreTipoCapex.toUpperCase() + '-' + fecha1 + '.xlsx';
        const token = localStorage.getItem('token');
        await this.matrizService.downloadResource(
          'exportarGeneralCapex',
          {
            idestado: this.idEstado,
            token: token,
            anio: this.anio,
            subarea: this.areas.toString().replaceAll('&', '%26')
          },
          nombreentregable
        );
        this.showLoading = false;
      }
    },
    async listarCapex() {
      this.listado = [];
      //let url = this.idTipoCapex != 5 ? 'listarOpexCampo' : 'listarOpexTI';
      const res = await this.matrizService.get().execResource('listarCapex', {
        idestado: this.idEstado,
        idleyendaformato: this.idTipoCapex,
        anio: this.anio,
        subarea: this.areas.toString().replaceAll('&', '%26')
      });
      this.listado = res.map((element, index) => {
        return { numero: index + 1, opciones: '', class: 'class-items-fixed-ind', ...element };
      });
      this.height = 370;
    },
    async initialize() {
      this.showLoading = true;
      await this.listarCapex();
      this.showLoading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    rutaPath(tipoCapex) {
      let ruta = '';
      switch (tipoCapex) {
        case 'camp':
          ruta = 'campo';
          break;
      }
      return ruta;
    },
    registro() {
      this.$router.push({
        path: `/presupuesto/capex-` + this.rutaPath(this.tipoCapex) + `/nuevo`
      });
    },
    editItem(item) {
      let id = this.tipoCapex == 'ti' ? item.idcapextibudget : item.idcapexbudget;
      this.$router.push({
        path: `/presupuesto/capex-` + this.rutaPath(this.tipoCapex) + `/editar`,
        query: { id: id }
      });
    },
    generateVersion(item) {
      let id = this.tipoCapex == 'ti' ? item.idcapextibudget : item.idcapexbudget;
      this.$router.push({
        path: `/presupuesto/capex-` + this.rutaPath(this.tipoCapex) + `/nueva-version`,
        query: { id: id }
      });
    },
    viewVersions(item) {
      let id = this.tipoCapex == 'ti' ? item.idcapextibudget : item.idcapexbudget;
      this.$router.push({
        path: `/presupuesto/capex-` + this.rutaPath(this.tipoCapex) + `/ver-versiones`,
        query: { id: id }
      });
    },
    async cambiarEstado(item, estado) {
      const res = await this.matrizService.post().execResource('cambiarEstado', {
        idopexbudget: item.idcapexbudget,
        estado: estado,
        idleyendaformato: this.idTipoCapex
      });
      this.alertDialog(res.status ? 'success' : 'error', res.data);
      if (res.status) this.initialize();
    },
    async aprobar(item, estado) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de ' + (estado == 1 ? 'aprobar' : 'anular') + ' el registro?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        this.cambiarEstado(item, estado == 1 ? 'AP' : 'AN');
        this.showLoading = false;
      }
    },
    async darVisto(item, numero) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Desea dar el Visto Bueno ' + numero + '?',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: 'SI',
        denyButtonText: 'NO',
        cancelButtonText: 'Cancelar'
      });
      if (confirmed.isConfirmed) {
        this.cambiarEstado(item, 'V' + numero);
      } else if (confirmed.isDenied) {
        this.cambiarEstado(item, 'R' + numero);
      }
    },
    async eliminar(item) {
      const res = await this.matrizService.post().execResource('eliminar', {
        idopexbudget: item.idcapexbudget,
        activo: 0,
        idleyendaformato: this.idTipoCapex
      });
      this.alertDialog(res.status ? 'success' : 'error', res.data);
      if (res.status) this.initialize();
    },
    async deleteItem(item) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar el registro?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        this.eliminar(item);
        this.showLoading = false;
      }
    },
    async intervaloFechas() {
      let currentYear = moment().year(); // año actual
      let currentMonth = moment().month(); // año actual
      let endMonthDays = moment([currentYear, currentMonth]).daysInMonth(); // número de días en el mes
      this.dateRange = [
        moment(currentYear + '-' + currentMonth + '-' + 1).format('YYYY-MM-DD'),
        moment(currentYear + '-' + currentMonth + '-' + endMonthDays).format('YYYY-MM-DD')
      ];
      this.dateRange = [
        moment([currentYear, currentMonth, 1]).format('YYYY-MM-DD'),
        moment([currentYear, currentMonth, endMonthDays]).format('YYYY-MM-DD')
      ];
    },
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('cargarAnios', {
        idempresa: emp,
        tipo: this.idTipoCapex
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async cargarAreas() {
      this.itemsAreas = [];
      this.listado.forEach((el) => {
        let exist = -1;
        exist = this.itemsAreas.findIndex((el2) => el2.value == el.IdArea);
        if (exist == -1 && el.IdArea != '') {
          this.itemsAreas.push({ value: el.IdArea, text: el.descripcionarea });
        }
      });
      this.itemsAreas.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    },
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    }
  },
  async mounted() {},
  async created() {
    this.fechaInicio = moment().format('YYYY-MM-DD');
    this.horaInicio = moment().format('HH:mm');
    this.matrizService = this.$httpService(new MatrizService(), this);
    //await this.permisos();
    await this.cargarAnios();
    //await this.intervaloFechas();
    await this.listarYCargarAreas();
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 100px;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
